@import 'setup/setup';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font: 14px / 24px 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  color: $black;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;

  a {
    font-weight: bold;
    transition: $transitionDuration;

    &:link,
    &:visited {
      color: $linksLinkVisited;
      text-decoration: none;
    }

    &:hover,
    &:active {
      color: $linksHoverActive;
      text-decoration: none;
      &[href='#curriculum'] {
        .fa {
          color: $fontAwesomeIcons;
          transition: $transitionDuration;
        }
      }
    }
  }
}

#curriculum {
  padding: 1em;
  background-color: white;

  .row {
    margin-top: 2em;
  }

  h3 {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    margin: 0 0 1em 0;
    text-transform: lowercase;
    color: $yellow;

    a {
      font-weight: normal;
      &:hover {
        ~ .job-title {
          color: $linksLinkVisited;
          transition: $transitionDuration;
        }
      }
    }

    .fa-twitter {
      margin-right: 5px;
    }
  }

  .nationality {
    display: inline-block;
    width: 2.5em;
  }

  .date-of-birth {
    display: inline-block;
    width: 1.7em;
  }

  .availability {
    display: inline-block;
    width: 2.5em;
  }

  .skill {
    white-space: nowrap;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 1em 0;
  }

  h5 {
    margin: 0 0 5px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  h6 {
    margin: 0 0 3px 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .from-to {
    margin: 0 0 3px 0;
  }

  .website {
    margin: 0 0 0 18px;
    padding: 0;

    li {
      list-style: square outside;
      color: $listItemBullets;

      .fa {
        font-weight: 400;
      }
    }
  }

  .points {
    margin: 18px;
    padding: 0;

    li {
      list-style: square outside;
      color: $listItemBullets;

      span {
        color: $black;
      }
    }
  }

  hr {
    margin: 0;
    border: 0;
    border-top: 1px solid $horizontalRule;
    border-left: none;
    border-right: none;
  }
}

@media only screen and (max-width: 576px) {
  #curriculum .website {
    margin-bottom: 1em;
  }
}

.fa {
  color: $fontAwesomeIcons;
}
