$black: #2a2a2a;
$white: #ffffff;
$green: #426100;
$yellow: #ad8200;

$linksLinkVisited: $green;
$linksHoverActive: $yellow;

$fontAwesomeIcons: $yellow;

$boxShadow: rgba($black, 0.3);

$buttonBgColor: rgba($linksHoverActive, 0.8);
$buttonBgColorHover: rgba($linksHoverActive, 0.5);

$curriculumBorderBottom: $yellow;

$horizontalRule: $yellow;

$listItemBullets: $yellow;
